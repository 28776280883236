<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            Password Reset
          </h2>
        </b-link>

        <!-- <b-card-title class="mb-1">
          Welcome to Vuexy! 👋
        </b-card-title> -->
        <b-card-text class="mb-2">
          We will send you an email to reset your password
        </b-card-text>

        <!-- form -->
        <validation-observer ref="resetForm">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="login-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="login-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="loading"
              @click="validationForm"
            >
              <b-spinner
                v-if="loading"
                small
                class="float-left"
                label="Floated Right"
              />
              Submit
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BFormGroup, BFormInput, BSpinner, BCardText, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import store from '@/store/index'

export default {
  components: {

    BCard,
    BLink,
    BFormGroup,
    BFormInput,

    // BFormCheckbox,
    BCardText,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      responseData: [],
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      const self = this
      this.$refs.resetForm.validate().then(success => {
        if (success) {
          self.loading = true
          const headers = {
            Accept: 'application/json',
          }
          axios.post(`${this.$appURL}smarttruck/reset-password`, {
            email: self.userEmail,
            url: `${this.$baseUrl}set_new_password`,
          }, { headers })
            .catch(err => {
              self.loading = false
            })
            .then(response => {
              self.loading = false
              if (response.data.code === 200) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    icon: 'CoffeeIcon',
                    variant: response.data.message ? 'success' : 'error',
                    text: response.data.message,
                  },
                })
                this.$router.push('login')
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
